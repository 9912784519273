body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.site-layout-content {
  background: #fff;
  padding: 24px;

}

.ant-statistic-title{
  font-size: 12px !important;
}
  [data-theme="dark"] .site-layout-content {
    background: white;
  }
  [data-theme="dark"] .logo {
  
    border: 1px solid white;
  }

  .ant-tabs-tab-active>div:target>a, .ant-tabs-tab-active>div.current>a{
    color: #414142 !important; 
  }

  .clearfloat { clear: both;}